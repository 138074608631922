export const vaellukset = [
  // {
  //   name: 'aloittelijoiden-vaellus-hetta-pallas-2023',
  //   title: 'Aloittelijoiden vaellus Hetta-Pallas 2023',
  //   dates: ['22.-25.6.2023', '20.-23.7.2023', '9.-12.9.2023', '16.-19.9.2023'],
  //   link: '/vaellukset/aloittelijoiden-vaellus-hetta-pallas-2023',
  // },
  {
    name: 'aloittelijoiden-vaellus-hetta-pallas-2025',
    title: 'Aloittelijoiden vaellus Hetta-Pallas 2025',
    dates: ['14.-17.6.2025', '19.-22.6.2025', '6.-9.9.2025', '13.-16.9.2025'],
    link: '/vaellukset/aloittelijoiden-vaellus-hetta-pallas-2025',
  },
  {
    name: 'haltin-vaellus',
    title: 'Haltin vaellus',
    dates: ['31.7.-3.8.2025'],
    link: '/vaellukset/haltin-vaellus',
  },
  // {
  //   name: 'pohjois-norjan-vaellus',
  //   title: 'Pohjois-Norjan vaellus',
  //   dates: ['31.7.-4.8.2024'],
  //   link: '/vaellukset/pohjois-norjan-vaellus',
  // },
];

export const hiihtovaellukset = [
  {
    name: 'kaamoshiihto-pallas-yllastunturin-kansallispuistossa',
    title: 'Kaamoshiihto Pallas-Yllästunturin kansallispuistossa',
    dates: ['5.-7.12.2025'],
    link: '/hiihtovaellukset/kaamoshiihto-pallas-yllastunturin-kansallispuistossa',
  },
  {
    name: 'aloittelijoiden-hetta-pallas-hiihtovaellus',
    title: 'Aloittelijoiden Hetta-Pallas hiihtovaellus',
    dates: ['21.-25.3.2025'],
    link: '/hiihtovaellukset/aloittelijoiden-hetta-pallas-hiihtovaellus',
  },
  {
    name: 'haltin-hiihtovaellus',
    title: 'Haltin hiihtovaellus',
    dates: ['4.-11.4.2025'],
    link: '/hiihtovaellukset/haltin-hiihtovaellus',
  },
];

export const maastopyoraily = [];

export const vapaalasku = [
  {
    name: 'kilpisjärven-vapaalaskuviikonloppu',
    title: 'Kilpisjärven vapaalaskuviikonloppu',
    dates: ['24.-26.4.2025'],
    link: '/vapaalasku/kilpisjärven-vapaalaskuviikonloppu',
  },
];

export const verkkokurssit = [
  {
    name: 'talviretkeilyn-turvallisuus',
    title: 'Talviretkeilyn turvallisuus',
    dates: ['25.1.2025 klo 18.30-20.30', '10.2.2025 klo 18.30-20.30'],
    link: '/verkkokurssit/talviretkeilyn-turvallisuus',
  },
];
